<template>
    <transition name="fade">
        <div
            v-if="placeholder"
            class="card-collection"
            :data-wide="wide"
        >
            <BaseCard
                v-for="(card, index) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]"
                :key="index"
                :uri="`/${card.uri}`"
                :class="{'card--small' : wide}"
            >
                <template #header>
                    <ContentPlaceholdersImg />
                </template>

                <template #title><ContentPlaceholdersHeading /></template>

                <template
                    v-if="card.meta || card.location"
                    #meta
                >
                    <ContentPlaceholdersText :lines="2" />
                </template>

                <template
                    v-if="card.pills"
                    #footer
                >
                    <ContentPlaceholdersText :lines="1" />
                </template>
            </BaseCard>
        </div>

        <div
            v-else
            class="card-collection"
            :data-wide="wide"
        >
            <BaseCard
                v-for="(card, index) in cards"
                :key="index"
                :uri="`/${card.uri}`"
                :class="{'card--small' : wide}"
            >
                <template
                    v-if="card.image"
                    #header
                >
                    <BaseFigure
                        v-bind="card.image"
                        alt=""
                        sizes="(min-width: 1400px) 407px, (min-width: 670px) 600px, 90vw"
                    />
                </template>

                <template #title>{{ card.title }}</template>

                <template
                    v-if="card.meta || card.location"
                    #meta
                >
                    <strong v-if="card.meta">{{ card.meta }}</strong>

                    <div
                        v-if="showLocation && card.location"
                        class="card__location"
                    >
                        {{ card.location }}
                    </div>
                </template>

                <template
                    v-if="card.pills"
                    #footer
                >
                    <template
                        v-for="(pill, pillDex) in card.pills.slice(0,4)"
                        :key="pillDex"
                    >
                        <BasePill :class="{ 'pill--dark': pill.isDark }">
                            {{ pill.text }}
                        </BasePill>
                        <span
                            v-if="card.pills.length > 4 && pillDex === 3"
                            :key="`hellip${pillDex}`"
                            class="card__footer-hellip"
                        >&hellip;</span>
                    </template>
                </template>
            </BaseCard>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        cards: {
            type: Array,
            default: null,
            required: true
        },
        placeholder: {
            type: Boolean,
            default: false,
            required: false
        },
        wide: {
            type: Boolean,
            default: false,
            required: false
        },
        showLocation: {
            type: Boolean,
            default: true,
            required: false
        }
    },
};
</script>

<style lang="less">
.card-collection {
    .card-grid();
}

.card-collection[data-wide='true'] {
    .card-grid-wide();
}

.card__location {
    display: block;
    font-weight: var(--font-weight-primary-normal);
}
</style>
